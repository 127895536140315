.wrapper {
  max-width: 100%;
  width: auto;
  /* margin: 40px auto; */
  padding: 0 20px;
  width: auto;
  display: flex;
  justify-content: center;
}

.wrapperInner {
  display: grid;
  grid-template-columns: auto auto auto auto;
}

.svgIcon {
  flex-shrink: 0;
  margin: auto;
}

.cardbox {
  max-width: 290px;
  max-height: 410px;
  padding: 5px;
  margin: 12px;
  background: #ffffff;
  border: #000000 solid 1px;
  border-radius: 20px;
  width: auto;
  cursor: pointer;
  box-sizing: border-box;
  border: 2px solid transparent;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.cardbox:hover {
  border-color: #617ad3;
}

.image {
  position: relative;
  background: #adadad;
  border-radius: 10px;
  width: 245px;
  height: 254px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
  margin-bottom: 8px;
}

.avatar:global(.ant-avatar) {
  position: absolute;
  bottom: -80px;
  left: 50%;
  transform: translate(-50%, 0px);
  border: 2px solid #f3f3f3;
}

.button:global(.ant-btn) {
  width: 100%;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

:global(.ant-btn).btnInfo:focus,
:global(.ant-btn).btnInfo:active,
:global(.ant-btn).btnInfo:hover,
.btnInfo:global(.ant-btn) {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to bottom, #27aae1, #617ad3) border-box;
  border: 1px solid transparent;
  color: #000000;
  font-family: "GILROY ";
}

:global(.ant-btn).btnInfo:hover {
  background: linear-gradient(180deg, #27aae1 0%, #617ad3 100%);
  color: white;
  border: none;
}

:global(.ant-btn).btnBuy:focus,
:global(.ant-btn).btnBuy:active,
:global(.ant-btn).btnBuy:hover,
.btnBuy:global(.ant-btn) {
  background: linear-gradient(180deg, #27aae1 0%, #617ad3 100%);
  color: white;
  border: none;
  font-family: "GILROY ";
}

:global(.ant-btn).btnBuy:hover {
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 10px 0px;
}

.title {
  /* color: #000000; */
  font-family: "GILROY ";
  font-size: 18px;
  font-weight: 700;
  padding-left: 10px;
}

.id {
  font-size: 16px;
  color: #adadad;
}

.price {
  font-size: 18px;
  font-weight: 700;
  color: var(--theme-color);
  font-family: "GILROY ";
}

.byAuthor span {
  color: var(--theme-color);
}

.description {
  font-size: 16px;
  font-family: "GILROY ";
}

.pagination:global(.ant-pagination) :global(.ant-pagination-item) {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to bottom, #27aae1, #617ad3) border-box;
  border-radius: 50%;
  border: 1px solid transparent;
}

.pagination:global(.ant-pagination) :global(.ant-pagination-item a) {
  background: linear-gradient(180deg, #27aae1 0%, #617ad3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 18px;
}

.pagination:global(.ant-pagination) :global(.ant-pagination-item:hover),
.pagination:global(.ant-pagination) :global(.ant-pagination-item-active) {
  background: linear-gradient(to bottom, #27aae1, #617ad3) border-box;
}

.pagination:global(.ant-pagination) :global(.ant-pagination-item:hover a),
.pagination:global(.ant-pagination) :global(.ant-pagination-item-active a) {
  background: linear-gradient(180deg, white 0%, white 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.bg {
  position: relative;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bannerWrapper {
  height: 500px;
  width: 100%;
}
.bannerImg {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.bgVideo {
  position: relative;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bannerContent {
  /* background: #f3f3f3; */
  padding-top: 80px;
  text-align: center;
}
.bannerTitle {
  font-size: 48px;
  /* color: #000000; */
  font-weight: 700;
}

.createdBy {
  font-size: 18px;
  /* color: #000000; */
}
.createdBy span {
  background: -webkit-linear-gradient(180deg, #27aae1, #617ad3 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.number {
  font-size: 30px;
  font-weight: 700;
  font-family: "GILROY ";
}
.attr {
  font-size: 18px;
}
.statics {
  display: flex;
  justify-content: center;
}
.statics :global(.ant-row) {
  padding: 10px 20px;
  border: 1px solid #adadad;
  border-radius: 10px;
  margin: 16px;
}
.desc {
  font-size: 20px;
  color: #000000;
  font-family: "GILROY ";
  padding: 10px 22%;
}

.icon {
  font-size: 25px;
  color: #617ad3 !important;
  font-family: "GILROY ";
}

.inputField {
  text-align: right;
  margin-bottom: -30px;
  margin-right: 40px;
}

.input:global(.ant-input-affix-wrapper) {
  /* background: transparent !important; */
  border-radius: 30px !important;
  align-items: center;
  width: 384px;
  height: 42px;
}

.input :global(.ant-input) {
  color: #000000 !important;
}

.input:hover {
  border-color: #fff !important;
}

/* bar */
.endRow {
  margin-top: 10px;

  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  color: #fff;
}
.socialIconsContainer {
  display: flex;
  margin-right: 20px;
}
.socialIconsWrapper {
  width: 100%;
}
.socialIconsContent {
  display: flex;
  justify-items: center;
  border: gray solid 1px;
  border-radius: 10px;
}
.socialIcon {
  width: 40px;
  height: 40px;
  padding: auto;
  margin: auto;
}
/* responsive */

.divider {
  border: gray solid 1px;
}

/* @media only screen and (min-width: 1450px) and (max-width: 1500px) {
  .wrapperInner {
    grid-template-columns: auto auto auto auto;
  }
  .cardbox {
    max-width: 282px;
    max-height: 410px;
    padding: 0px !important;
    margin: 12px !important;
    margin-top: 20px !important;
  }
} */

@media only screen and (min-width: 1200px) and (max-width: 1450px) {
  .wrapperInner {
    grid-template-columns: auto auto auto;
  }
}
@media screen and (max-width: 1200px) {
  .wrapperInner {
    grid-template-columns: auto auto auto;
  }
  .cardbox {
    max-width: 282px;
    max-height: 410px;
    padding: 0px !important;
    margin: 5px !important;
    margin-top: 20px !important;
  }
}
@media only screen and (min-width: 0px) and (max-width: 550px) {
  .socialIconsContainer {
    /* `flex text-3xl mb-[-2rem] lg:absolute`, */
    display: flex;
    /* margin-right: 20% !important; */
    /* position: absolute; */
    margin-top: 80px;
    justify-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 992px) {
  .number {
    font-size: 18px;
  }
  .attr {
    font-size: 16px;
  }
  .icon {
    font-size: 16px;
  }
  .statics :global(.ant-row) {
    padding: 10px;
  }
  .wrapperInner {
    grid-template-columns: auto auto;
  }
}

@media screen and (max-width: 576px) {
  .wrapperInner {
    grid-template-columns: auto;
  }
}

@media screen and (max-width: 310px) {
  .wrapperInner {
    margin-left: -25px !important;
  }
  .inputField {
    width: 50px !important;
  }
  .number {
    font-size: 10px !important;
  }
  .attr {
    font-size: 10px !important;
  }
  .icon {
    font-size: 10px !important;
  }
  .desc {
    font-size: 14px !important;
  }

  .title {
    /* color: #000000; */
    font-family: "GILROY ";
    font-size: 16px;
    font-weight: 700;
  }
  .button:global(.ant-btn) {
    font-size: 14px;
  }
}
