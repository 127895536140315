.exploreBtn:global(.ant-btn) {
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  border-color: var(--theme-color);
  margin-bottom: 40px;
}

.exploreBtn:global(.ant-btn:hover),
.exploreBtn:global(.ant-btn:focus) {
  color: #ffffff;
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color-bold) 100%);
  border: none;
}

.exploreBtn:global(.ant-btn:focus) span,
.exploreBtn:global(.ant-btn:hover) span {
  background: -webkit-linear-gradient(#ffffff, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.exploreBtn span {
  font-weight: 700;
  background: -webkit-linear-gradient(var(--theme-color), var(--theme-color-bold));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*  */

.button:global(.ant-btn) {
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  border-color: var(--theme-color-bold);
  margin-bottom: 40px;
}
.btnInfo:global(.ant-btn) {
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  border-color: var(--theme-color-bold);
  margin-bottom: 40px;
}

:global(.ant-btn).btnInfo:focus,
:global(.ant-btn).btnInfo:active,
:global(.ant-btn).btnInfo:hover,
.btnInfo:global(.ant-btn) {
  background: 
    linear-gradient(to bottom, var(--theme-color), var(--theme-color-bold)) border-box;
  border: 1px solid transparent;
  color: white;
}

:global(.ant-btn).btnInfo:hover {
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color-bold) 100%);
  color: white;
  border: none;
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 10px 0px;

}

/*  */
.infoBtn:global(.ant-btn) {
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  border-color: var(--theme-color);
  margin-bottom: 40px;
}
.infoBtn:global(.ant-btn:hover),
.infoBtn:global(.ant-btn:focus) {
  color: #ffffff;
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color-bold) 100%);
  border: none;
}

.infoBtn:global(.ant-btn:focus) span,
.infoBtn:global(.ant-btn:hover) span {
  background: -webkit-linear-gradient(#ffffff, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.infoBtn span {
  font-weight: 700;
  background: -webkit-linear-gradient(var(--theme-color), var(--theme-color-bold));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* buy */
/* :global(.ant-btn).exploreBtn:focus,
:global(.ant-btn).exploreBtn:active,
:global(.ant-btn).exploreBtn:hover,
.exploreBtn:global(.ant-btn) {
  background: linear-gradient(180deg, #27aae1 0%, #617ad3 100%);
  color: white;
  border: none;
  font-family: "GILROY ";
} */
/* buy */

:global(.ant-btn).btnBuy:hover {
  box-shadow: rgb(4 17 29 / 25%) 0px 0px 10px 0px;
}



/* share button */

.shareBtn {
  background: #ffffff;
  border-radius: 10px;
  border: var(--theme-color) solid 1px;
  /* width: calc(auto -10px);
  height: calc(auto -50px); */
  color: var(--theme-color);
  min-width: 70px;
}
.shareBtn:hover,
.shareBtn:focus {
  color: var(--theme-color-bold);
  /* background: linear-gradient(180deg, #27aae1 0%, #617ad3 100%); */
  border: var(--theme-color) solid 1px;
}
.shareBtn:focus span,
.shareBtn:hover span {
  background: var(--theme-color-bold);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.shareBtn span {
  font-weight: 700;
  background: -webkit-linear-gradient(var(--theme-color), var(--theme-color-bold));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/* view button */

.viewBtn:global(.ant-btn) {
  background: #ffffff;
  border-radius: 10px;
  width: auto;
  /* min-height: 40px; */
  border-color: var(--theme-color);
  /* margin-top: -7px; */
  margin-bottom: 30px;
}

.viewBtn:global(.ant-btn:hover),
.viewBtn:global(.ant-btn:focus) {
  color: #ffffff;
  background: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color-bold) 100%);
  border: none;
}

.viewBtn:global(.ant-btn:focus) span,
.viewBtn:global(.ant-btn:hover) span {
  background: -webkit-linear-gradient(#ffffff, #ffffff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.viewBtn span {
  font-weight: 700;
  background: -webkit-linear-gradient(var(--theme-color), var(--theme-color-bold));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* responsive */
@media screen and (max-width: 1199px) {
  .exploreBtn:global(.ant-btn) {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 1023px) {
  .exploreBtn:global(.ant-btn) {
    margin-bottom: 15px;
  }

  .notableInfo {
    padding: 0 15px 0;
  }
}

/* @media screen and (max-width: 991px) {
} */