@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";

// .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
// .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
// .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
// .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
// .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
// .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
// .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
// .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
//   // border-bottom: 2px solid #21bf96 !important;
// }

.ant-menu-horizontal > .ant-menu-item-selected a,
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #ffffff !important;
}

.ant-modal-content {
  overflow: auto;
  border-radius: 1rem;
}

.ant-menu-horizontal {
  border-bottom: #21bf96;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--theme-color-bold); //var(--theme-color);
  text-shadow: 0 0 0.25px currentColor;
}

.runeBtn {
  border-radius: 10px;
  width: 115px;
  height: 115px;
  border-color: #172430;
  background: #172430;
  text-align: center;
  padding: 8px;
  color: gray;
}

.runeBtn2 {
  border-radius: 10px;
  width: 115px;
  height: 115px;
  border-color: #172430;
  background: #21bf96;
  text-align: center;
  padding: 8px;
}

.runeBtn:hover {
  border-color: #324252;
  background: #324252;
  cursor: pointer;
}

.runeBtn2:hover {
  cursor: pointer;
}

.ant-card {
  border: solid 1px #324252;
}

.ant-card:hover {
  border: solid 1px #324252;
}

.link-custom:hover,
.link-custom {
  color: unset;
}

.input-text {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 5px;
  height: 28px;
  color: #000000;
  font-weight: 700;
}

@media screen and (max-width: 991px) {
  .ant-layout.ant-layout-has-sider {
    // padding-bottom: 45px !important;
  }
}

.icon-logo {
  font-size: 42px;
  width: 42px !important;
  height: 42px;
  min-width: 42px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: linear-gradient(
    180deg,
    var(--theme-color) 0%,
    var(--theme-color-bold) 100%
  );
  border-radius: 50%;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .icon-logo {
    font-size: 30px;
    width: 30px !important;
    height: 30px;
    min-width: 30px;
  }
}

.ant-pagination-item-active a {
  color: #ffffff !important;
}
.ant-pagination-item {
  border: none !important;
}

@component-background: white;@text-color: black;@primary-5: black;@primary-color: darkslategrey;@heading-color: var(--theme-color);@layout-header-background: #001529;@menu-item-active-bg: darkslategrey;@menu-dark-item-active-bg: darkslategrey;@link-color: var(--theme-color);@timeline-dot-bg: transparent;@table-bg: #001529;@table-header-bg: #001529;@table-header-color: var(--theme-color);@table-row-hover-bg: darkslategrey;@pagination-item-bg: transparent;@pagination-item-bg-active: linear-gradient(180deg, var(--theme-color) 0%, var(--theme-color-bold) 100%);@btn-link-hover-bg: #001529;@icon-color-hover: #001529;@icon-color: black;