.node__root > circle {
  fill: #8578ff;
}
.node__root text {
  transform: translateX(-20px) !important;
}
.node__branch > circle {
  fill: var(--theme-color);
  transform: translateX(10);
}
.node__branch text {
  transform: translateX(-20px) !important;
  /* transform: translateY(5px) !important; */
}

.node__leaf > circle {
  fill: #333333;
}

.node__leaf text {
  transform: translateX(-20px) !important;
}
